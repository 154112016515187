export const getAllRace = async (
  venueList = [],
  driverList = [],
  startDateRange,
  endDateRange
) => {
  const params = new URLSearchParams();
  venueList.forEach((venue) => params.append("venue", venue));
  driverList.forEach((driver) => params.append("driver", driver));
  if (startDateRange) params.append("start_date_range", startDateRange);
  if (endDateRange) params.append("end_date_range", endDateRange);
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const apiUrl = `${baseUrl}/time-series-api`;

  const response = await fetch(`${apiUrl}/race?${params.toString()}`);
  const data = await response.json();
  return data;
};

export const getAllVenues = async () => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const apiUrl = `${baseUrl}/time-series-api`;

  const response = await fetch(`${apiUrl}/util/venues`);
  const data = await response.json();
  return data;
};

export const getAllDrivers = async () => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const apiUrl = `${baseUrl}/time-series-api`;

  const response = await fetch(`${apiUrl}/util/drivers`);
  const data = await response.json();
  return data;
};

export const getRaceDetails = async (raceId) => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const apiUrl = `${baseUrl}/time-series-api`;

  const response = await fetch(
    `${apiUrl}/race/${raceId}/details?load_metadata=true`
  );
  const data = await response.json();
  return data;
};

export const cleanTempFiles = (raceId, fileName, downloadFormat) => {
  const baseUrl = `${window.location.protocol}//${window.location.host}`;
  const apiUrl = `${baseUrl}/time-series-api`;

  fetch(
    `${apiUrl}/race/${raceId.toString()}/cleanup?file_name=${encodeURIComponent(
      fileName
    )}&download_format=${encodeURIComponent(downloadFormat)}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
