import React from "react";
import { Modal, Box, CircularProgress, Typography } from "@mui/material";

const DownloadModal = ({ isOpen, onClose }) => (
  <Modal
    open={isOpen}
    onClose={onClose}
    aria-labelledby="download-modal-title"
    aria-describedby="download-modal-description"
    style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    <Box
      textAlign="center"
      bgcolor="background.paper"
      boxShadow={24}
      p={4}
      style={{
        outline: "none",
        borderRadius: "4px",
      }}
    >
      <CircularProgress />
      <Typography
        id="download-modal-title"
        variant="h6"
        component="h2"
        sx={{ mt: 2 }}
      >
        Downloading... 🚗💨
      </Typography>
      <Typography id="download-modal-description" sx={{ mt: 2 }}>
        Please wait while we prepare your download.
      </Typography>
    </Box>
  </Modal>
);

export default DownloadModal;
