// App.js
import React, { useState } from "react";
import Header from "../src/components/upload-panel/Header";
import UploadData from "../src/components/upload-panel/UploadData";
import DownloadData from "../src/components/download-panel/DownloadData";
import { Tabs, Tab, Box } from "@mui/material";
import "./App.css";

function App() {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div className="App">
      <Header />
      <main className="container py-5">
        <div className="card mat-elevation-z3">
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={selectedTab}
              onChange={handleTabChange}
              aria-label="basic tabs example"
              variant="fullWidth"
              className="tab-no-outline"
            >
              <Tab
                icon={<span className="material-icons">upload</span>}
                label="Upload"
              />
              <Tab
                icon={<span className="material-icons">download</span>}
                label="Download"
              />
            </Tabs>
          </Box>
          <div className="card-body">
            {selectedTab === 0 && <UploadData />}
            {selectedTab === 1 && <DownloadData />}
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
