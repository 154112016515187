import {
  Accordion,
  Grid,
  Typography,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  FormControl,
  Checkbox,
  Autocomplete,
  TextField,
  IconButton,
  CircularProgress,
  Button,
  Chip,
} from "@mui/material";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EventIcon from "@mui/icons-material/Event";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PersonIcon from "@mui/icons-material/Person";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

function SelectRace({
  selectedRaceId,
  onRaceSelect,
  raceFilter,
  setRaceFilter,
  fetchRaceList,
  venueList,
  driverList,
  isRaceListLoading,
  raceList,
}) {
  const handleFilterChange = (event, newValue, name) => {
    setRaceFilter({
      ...raceFilter,
      [name]: newValue,
    });
  };

  return (
    <>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Filters</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={venueList}
                  value={raceFilter.venues}
                  onChange={(event, newValue) =>
                    handleFilterChange(event, newValue, "venues")
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} label="Venues" variant="outlined" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  disableCloseOnSelect
                  options={driverList}
                  value={raceFilter.drivers}
                  onChange={(event, newValue) =>
                    handleFilterChange(event, newValue, "drivers")
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip label={option} {...getTagProps({ index })} />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option}
                    </li>
                  )}
                  getOptionLabel={(option) => option}
                  renderInput={(params) => (
                    <TextField {...params} label="Drivers" variant="outlined" />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Choose a start date"
                type="date"
                name="startDateRange"
                value={raceFilter.startDateRange || ""}
                onChange={(event) =>
                  setRaceFilter({
                    ...raceFilter,
                    startDateRange: event.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Choose an end date"
                type="date"
                name="endDateRange"
                value={raceFilter.endDateRange || ""}
                onChange={(event) =>
                  setRaceFilter({
                    ...raceFilter,
                    endDateRange: event.target.value,
                  })
                }
                InputLabelProps={{ shrink: true }}
                variant="outlined"
              />
            </Grid>
          </Grid>
        </AccordionDetails>
        <AccordionActions>
          <Button
            onClick={() =>
              setRaceFilter({
                venues: [],
                drivers: [],
                startDateRange: null,
                endDateRange: null,
              })
            }
            color="secondary"
          >
            Reset Filters
          </Button>
          <Button onClick={fetchRaceList} color="primary">
            Apply Filters
          </Button>
        </AccordionActions>
      </Accordion>
      {isRaceListLoading && <CircularProgress />}
      <div className="my-4">
        {raceList.map((race) => (
          <div className="border rounded-1 my-2" key={race.id}>
            <div className="d-flex align-items-center justify-content-between py-2 px-3">
              <div style={{ width: "20%" }}>
                <Typography variant="h6">{race.venue}</Typography>
              </div>

              <div
                className="d-flex align-items-center"
                style={{ width: "60%" }}
              >
                <IconButton>
                  <EventIcon />
                </IconButton>
                <span className="ms-2">
                  {new Date(race.date).toLocaleDateString()}
                </span>
                <IconButton>
                  <ScheduleIcon />
                </IconButton>
                <span className="ms-2">{race.time}</span>
                <IconButton>
                  <PersonIcon />
                </IconButton>
                <span className="ms-2">{race.driver}</span>
                <IconButton>
                  <DirectionsCarIcon />
                </IconButton>
                <span className="ms-2">{race.vehicle_desc}</span>
              </div>

              {selectedRaceId === race.id ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onRaceSelect(race.id)}
                >
                  Selected
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => onRaceSelect(race.id)}
                >
                  Select
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default SelectRace;
