import React, { useState, useEffect, useCallback } from "react";
import {
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Button,
} from "@mui/material";
import SelectRace from "./SelectRace";
import { getAllRace, getAllVenues, getAllDrivers } from "./ApiService";
import RaceFilter from "./RaceFilter";
import Results from "./Results";

function DownloadPanel() {
  const [raceFilter, setRaceFilter] = useState({
    venues: [],
    drivers: [],
    startDateRange: null,
    endDateRange: null,
  });
  const [activeStep, setActiveStep] = useState(0);
  const [raceList, setRaceList] = useState([]);
  const [selectedRaceId, setSelectedRaceId] = useState(null);
  const [venueList, setVenueList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [isRaceListLoading, setIsRaceListLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedRace, setSelectedRace] = useState(null);
  const [channelData, setChannelData] = useState([]);
  const [lapData, setLapData] = useState([]);
  const initialFilterFormGroup = {
    channelNames: [],
    lapNumbers: [],
    shouldLoadLapData: true,
    shouldLoadLapDataInRawForm: false,
    newChannelName: true,
  };
  const [filterFormGroup, setFilterFormGroup] = useState(
    initialFilterFormGroup
  );

  // to fetch the details of all the races
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const venues = await getAllVenues();
        const drivers = await getAllDrivers();
        const races = await getAllRace();

        setVenueList(venues);
        setDriverList(drivers);
        setRaceList(races);
      } catch (err) {
        setError(err.message);
      }
      setIsLoading(false);
    };

    fetchData();
  }, []);

  // To fetch the details of a selected race
  useEffect(() => {
    const fetchRaceDetails = async () => {
      if (!selectedRaceId) return;

      setIsLoading(true);
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const apiUrl = `${baseUrl}/time-series-api`;

      try {
        const response = await fetch(
          `${apiUrl}/race/${selectedRaceId}/details?load_metadata=true`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch race details");
        }
        const data = await response.json();

        // Calculating the average lap duration and map the laps data
        const avgLapDuration =
          data.laps.reduce(
            (acc, curr, index, arr) =>
              index !== 0 && index !== arr.length - 1 ? acc + curr : acc,
            0
          ) /
          (data.laps.length - 2);

        const lapData = data.laps.map((duration, index) => ({
          averageDuration: avgLapDuration,
          lapIndex: index,
          duration: duration,
        }));

        // Update the state with the fetched and processed data
        setSelectedRace(data);
        setChannelData(data.channel_data || []);
        setLapData(lapData);
      } catch (error) {
        console.error("Error fetching race details:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchRaceDetails();
  }, [selectedRaceId]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const onRaceSelect = useCallback((id) => {
    setSelectedRaceId(id);
    handleNext();
  }, []);

  const resetFilterFormGroup = () => {
    setFilterFormGroup(initialFilterFormGroup);
  };

  const fetchRaceList = useCallback(async () => {
    setIsRaceListLoading(true);
    try {
      const races = await getAllRace(
        raceFilter.venues,
        raceFilter.drivers,
        raceFilter.startDateRange,
        raceFilter.endDateRange
      );
      setRaceList(races);
    } catch (err) {
      setError(err.message);
    }
    setIsRaceListLoading(false);
  }, [raceFilter]);

  return (
    <div>
      <Stepper activeStep={activeStep} alternativeLabel>
        <Step key="Select Race">
          <StepLabel>Select Race</StepLabel>
        </Step>
        <Step key="Apply Filters">
          <StepLabel>Apply Filters</StepLabel>
        </Step>
        <Step key="Results">
          <StepLabel>Results</StepLabel>
        </Step>
      </Stepper>
      <div>
        {activeStep === 0 && (
          <>
            <SelectRace
              selectedRaceId={selectedRaceId}
              raceFilter={raceFilter}
              setRaceFilter={setRaceFilter}
              fetchRaceList={fetchRaceList}
              venueList={venueList}
              driverList={driverList}
              isRaceListLoading={isRaceListLoading}
              raceList={raceList}
              onRaceSelect={onRaceSelect}
            />
          </>
        )}
        {activeStep === 1 && (
          <>
            <div className="my-4">
              {isRaceListLoading && <CircularProgress />}
              {!isRaceListLoading && selectedRace && (
                <form>
                  <RaceFilter
                    selectedRace={selectedRace}
                    channelData={channelData}
                    lapData={lapData}
                    filterFormGroup={filterFormGroup}
                    setFilterFormGroup={setFilterFormGroup}
                  />
                  <div className="d-flex my-3 mt-5 justify-content-between">
                    <Button
                      onClick={() => {
                        resetFilterFormGroup();
                        handleBack();
                      }}
                    >
                      Back
                    </Button>
                    <Button
                      onClick={handleNext}
                      color="primary"
                      disabled={!filterFormGroup}
                    >
                      Next
                    </Button>
                  </div>
                </form>
              )}
            </div>
          </>
        )}
        {activeStep === 2 && (
          <>
            <div className="my-4">
              <Results
                selectedRaceID={selectedRaceId}
                filterFormGroup={filterFormGroup}
                selectedRace={selectedRace}
              />
              <div className="d-flex my-3 mt-5 justify-content-between">
                <Button onClick={handleBack}>Back</Button>
                <Button
                  onClick={() => {
                    resetFilterFormGroup();
                    handleReset();
                  }}
                  color="secondary"
                  disabled={!filterFormGroup}
                >
                  Reset Filters
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default DownloadPanel;
