import React, { useState, useEffect, useCallback } from "react";
import { Button, Typography, IconButton } from "@mui/material";

import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DownloadModal from "./DownloadModal";

function Results({ selectedRaceID, filterFormGroup, selectedRace }) {
  const [downloadUrl, setDownloadUrl] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const [showLDDownloadButton, setShowLDDownloadButton] = useState(false);
  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const getDownloadFileName = useCallback(
    (filters) => {
      const filename = selectedRace.file_name.split(".").shift() || "download";
      const hasFilters =
        filters.lapNumbers.length > 0 || filters.channelNames.length > 0;
      return hasFilters ? `${filename}_sub` : filename;
    },
    [selectedRace]
  );

  useEffect(() => {
    const host = `${window.location.protocol}//${window.location.host}`;
    const apiUrl = `${host}/time-series-api`;
    let constructedUrl = `${apiUrl}/race/${selectedRaceID}/details/download?`;
    let lapQuery = filterFormGroup.lapNumbers.reduce(
      (acc, lapNum) => `${acc}lap_number=${lapNum}&`,
      ""
    );
    let channelQuery = filterFormGroup.channelNames.reduce(
      (acc, channel) => `${acc}channel_id=${channel}&`,
      ""
    );

    constructedUrl += `${lapQuery}${channelQuery}`;
    constructedUrl += `load_lap_data=${filterFormGroup.shouldLoadLapData}&`;
    constructedUrl += `load_raw_data=${filterFormGroup.shouldLoadLapDataInRawForm}&`;
    constructedUrl += `new_channel_names=${filterFormGroup.newChannelName}&`;
    constructedUrl += `file_name=${getDownloadFileName(filterFormGroup)}&`;

    setDownloadUrl(constructedUrl);
    setShowLDDownloadButton(
      filterFormGroup.shouldLoadLapData &&
        filterFormGroup.shouldLoadLapDataInRawForm
    );
  }, [selectedRaceID, filterFormGroup, getDownloadFileName]);

  const copyTargetLink = (downloadFormat) => {
    const link = `${downloadUrl}download_format=${downloadFormat}`;
    navigator.clipboard.writeText(link);
  };

  // Api Call Trigger the Download of the file
  const triggerDownloadAPI = (downloadFormat) => {
    setIsDownloading(true);
    setIsDownloadModalOpen(true); // Show the download modal
    const url = `${downloadUrl}download_format=${downloadFormat}`;
    const fileName = getDownloadFileName(filterFormGroup);

    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${fileName}.${downloadFormat}`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        cleanTempFiles(selectedRaceID, fileName.split(".")[0], downloadFormat);
      })
      .catch((error) => {
        console.error("Download failed", error);
        setIsDownloading(false);
        setIsDownloadModalOpen(false); // Hide the download modal
      });
  };

  // Api Call to clean the Temporary Files
  const cleanTempFiles = (raceId, fileName, downloadFormat) => {
    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    const apiUrl = `${baseUrl}/time-series-api`;

    fetch(
      `${apiUrl}/race/${raceId.toString()}/cleanup?file_name=${encodeURIComponent(
        fileName
      )}&download_format=${encodeURIComponent(downloadFormat)}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.text())
      .then(() => {
        setIsDownloading(false);
        setIsDownloadModalOpen(false);
      })
      .catch((error) => {
        console.error("Cleanup failed", error);
      });
  };

  return (
    <div>
      <div className="my-3 p-3 border rounded-1">
        <Typography variant="h6">JSON Target URL:</Typography>
        <div className="my-3 p-4 border rounded-1 position-relative">
          <Typography variant="body1" className="fst-italic text-break d-block">
            {downloadUrl}download_format=json
          </Typography>
          <IconButton
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              transform: "translate(-50%, 50%)",
            }}
            onClick={() => copyTargetLink("json")}
          >
            <ContentCopyIcon />
          </IconButton>
        </div>
        <div className="my-3 d-flex align-items-center">
          <Button
            variant="contained"
            color="primary"
            startIcon={<FileDownloadIcon />}
            onClick={() => triggerDownloadAPI("json")}
          >
            Download Response in JSON
          </Button>
        </div>
      </div>

      {showLDDownloadButton && (
        <div className="my-3 p-3 border rounded-1">
          <Typography variant="h6">LD Target URL:</Typography>
          <div className="my-3 p-4 border rounded-1 position-relative">
            <Typography
              variant="body1"
              className="fst-italic text-break d-block"
            >
              {downloadUrl}download_format=ld
            </Typography>
            <IconButton
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                transform: "translate(-50%, 50%)",
              }}
              onClick={() => copyTargetLink("ld")}
            >
              <ContentCopyIcon />
            </IconButton>
          </div>
          <div className="my-3 d-flex align-items-center">
            <Button
              variant="contained"
              color="primary"
              startIcon={<FileDownloadIcon />}
              onClick={() => triggerDownloadAPI("ld")}
            >
              Download Response in LD
            </Button>
          </div>
        </div>
      )}
      <DownloadModal
        isOpen={isDownloadModalOpen}
        onClose={() => setIsDownloadModalOpen(false)}
      />
    </div>
  );
}

export default Results;
