import React from "react";
import { AppBar, Toolbar, Typography, IconButton, Button } from "@mui/material";
import Logo from "../../images/trd_logo.webp";
import { version, version_deployed_date } from "../../utils.js";

const Header = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton edge="start" aria-label="logo">
          <img src={Logo} alt="TRD Logo" height="25" />
        </IconButton>
        <Typography variant="h6" style={{ flexGrow: 1 }}>
          Lap and Channel Database App
        </Typography>
        <Typography variant="body2" style={{ marginRight: "1rem" }}>
          {`v${version} - ${version_deployed_date}`}
        </Typography>
        <Button
          color="inherit"
          onClick={() =>
            window.open(`${window.location.origin}/api.html`, "_blank")
          }
        >
          API Doc
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
