import React, { useState, useCallback } from "react";
import { Button } from "@mui/material";
import ServerTerminalDialog from "./ServerTerminalDialog";

const UploadData = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [startUpload, setStartUpload] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file && (file.name.endsWith(".json") || file.name.endsWith(".ld"))) {
      setSelectedFile(file);
    } else {
      alert("Please select a .json or .ld file.");
    }
    event.target.value = null; // Resetting input after file selection
  };

  // const handleUpload = () => {
  //   if (selectedFile) {
  //     setIsDialogOpen(true); // Open the dialog when the upload button is clicked
  //   }
  // };

  const handleRemoveFile = () => {
    setSelectedFile(null);
  };

  const handleDragOver = useCallback((event) => {
    event.preventDefault();
  }, []);

  const handleDrop = useCallback((event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      const file = files[0];
      if (file.name.endsWith(".json") || file.name.endsWith(".ld")) {
        setSelectedFile(file);
      } else {
        alert("Please drop a .json or .ld file.");
      }
    }
    event.dataTransfer.clearData();
  }, []);

  const styles = {
    uploadPanel: {
      padding: "20px",
      border: "1px dashed #6c757d",
      height: "400px",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
    },
    fileUploadSection: {
      marginBottom: "20px",
      textAlign: "center",
    },
    removeFileButton: {
      marginLeft: "10px",
    },
  };

  return (
    <div
      className="upload-panel"
      style={styles.uploadPanel}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
    >
      <div className="file-upload-section" style={styles.fileUploadSection}>
        <h6>Drag and Drop a .json or .ld file here!</h6>
        <h6>or</h6>
        <label>
          <Button variant="contained" color="primary" component="span">
            Select File
          </Button>
          <input
            type="file"
            onChange={handleFileChange}
            style={{ display: "none" }}
            accept=".json,.ld"
          />
        </label>
      </div>
      {selectedFile && (
        <div style={{ textAlign: "center" }}>
          <span>Selected File: {selectedFile.name}</span>
          <Button
            variant="text"
            color="secondary"
            style={styles.removeFileButton}
            onClick={handleRemoveFile}
          >
            x
          </Button>
        </div>
      )}

      <div style={styles.uploadButtonContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (selectedFile) {
              setIsDialogOpen(true); // Open the dialog
              setStartUpload(true); // Set the flag to start upload
            }
          }}
          disabled={!selectedFile}
        >
          Upload
        </Button>
      </div>

      <ServerTerminalDialog
        open={isDialogOpen}
        onClose={() => {
          setIsDialogOpen(false);
          handleRemoveFile();
          setStartUpload(false); // Reset the upload flag when closing the dialog
        }}
        file={selectedFile}
        startUpload={startUpload}
      />
    </div>
  );
};

export default UploadData;
