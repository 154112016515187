import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  LinearProgress,
  Typography,
} from "@mui/material";
import axios from "axios";
import DownloadIcon from "@mui/icons-material/Download";
import io from "socket.io-client";

const ServerTerminalDialog = ({ open, onClose, file, startUpload }) => {
  const [dataUploadPercentage, setDataUploadPercentage] = useState(0);
  const [isDataParsing, setIsDataParsing] = useState(false);
  const [processingMessage, setProcessingMessage] = useState("");
  const [logFileUrl, setLogFileUrl] = useState("");
  const [logFileName, setLogFileName] = useState("");
  const [isProcessing, setIsProcessing] = useState(true);

  useEffect(() => {
    const socket = io(`${window.location.origin}`);

    socket.on("processingComplete", (data) => {
      setLogFileUrl(data.filePath);
      setLogFileName(data.fileName); // Set the log file name for download
      setProcessingMessage(
        "Data parsing completed. Download log file to see the detailed report."
      );
      setIsDataParsing(false);
      setIsProcessing(false);
      // Ensure we remove the progress indicator
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleUpload = () => {
    if (file && startUpload) {
      const formData = new FormData();
      formData.append("file", file);

      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setDataUploadPercentage(percentCompleted);
        },
      };

      setIsDataParsing(true);
      const baseUrl = `${window.location.protocol}//${window.location.host}`;
      const apiUrl = `${baseUrl}/time-series-api`;

      axios
        .post(`${apiUrl}/upload`, formData, config)
        .then((response) => {
          if (
            response.data &&
            response.data.message ===
              "File uploaded to s3 and processing started"
          ) {
            setProcessingMessage("Uploaded successfully and being processed.");
          } else {
            setProcessingMessage("Upload failed. Please try again.");
            console.error("Upload failed with response: ", response.data);
            alert("Upload failed. Please try again.");
            onclose();
          }
        })
        .catch((error) => {
          setIsDataParsing(false);
          setProcessingMessage(
            "Upload failed due to a network or server error. Please try again."
          );
          console.error("Upload failed: ", error.message);
          alert(
            "Upload failed due to a network or server error. Please try again."
          );
          onClose();
        })
        .finally(() => {
          setIsDataParsing(false);
        });
    }
  };

  useEffect(() => {
    setDataUploadPercentage(0);
    setIsDataParsing(false);
    setProcessingMessage("");
    setLogFileUrl("");
    setLogFileName("");
    setIsProcessing(true);
    handleUpload();
  }, [file, startUpload]);

  const downloadLogFile = () => {
    if (!logFileName) {
      console.error("Log file name is not set.");
      return;
    }

    // Construct the URL with the log file name as a query parameter
    const base = `${window.location.protocol}//${window.location.host}`;
    const apiUrl = `${base}/time-series-api`;
    const baseUrl = `${apiUrl}/upload/download-log`;
    const downloadUrl = `${baseUrl}?log_file_name=${logFileName}`;

    // Fetch the file using the constructed URL
    fetch(downloadUrl)
      .then((response) => {
        if (response.ok) return response.blob();
        throw new Error("Network response was not ok.");
      })
      .then((blob) => {
        // Create a URL for the blob
        const blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.download = logFileName; // Use the log file name for the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(blobUrl); // Clean up
      })
      .catch((error) => {
        console.error("Error downloading the log file:", error);
      });
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Uploading Data to TRD Time Series Database</DialogTitle>
      <DialogContent>
        <div style={{ width: "100%" }}>
          {isDataParsing ? (
            <>
              <Typography>
                Uploading Data File ({file?.name}): {dataUploadPercentage}%
              </Typography>
              <LinearProgress
                variant="determinate"
                value={dataUploadPercentage}
              />
              <CircularProgress
                style={{ display: "block", margin: "20px auto" }}
              />
            </>
          ) : isProcessing ? (
            <>
              <Typography>{processingMessage}</Typography>
              <CircularProgress
                style={{ display: "block", margin: "20px auto" }}
              />
            </>
          ) : (
            <Typography>{processingMessage}</Typography>
          )}
          {logFileName && (
            <Button onClick={downloadLogFile} startIcon={<DownloadIcon />}>
              Download Log
            </Button>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ServerTerminalDialog;
