import React from "react";
import {
  FormControl,
  Checkbox,
  Typography,
  IconButton,
  Switch,
  TextField,
  Autocomplete,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import EventIcon from "@mui/icons-material/Event";
import ScheduleIcon from "@mui/icons-material/Schedule";
import PersonIcon from "@mui/icons-material/Person";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

function RaceFilter({
  selectedRace,
  channelData,
  lapData,
  filterFormGroup,
  setFilterFormGroup,
}) {
  const getDiff = (lapDuration, averageDuration) => {
    return lapDuration > averageDuration
      ? lapDuration - averageDuration
      : averageDuration - lapDuration;
  };

  const compareDuration = (lapDuration, averageDuration) => {
    if (lapDuration < averageDuration - 1) return -1;
    if (lapDuration > averageDuration + 1) return 1;
    return 0;
  };

  const handleFilterFormGroup = (event) => {
    const { name, value, checked } = event.target;

    setFilterFormGroup((prev) => {
      if (name === "channelNames" || name === "lapNumbers") {
        return {
          ...prev,
          [name]: typeof value === "string" ? value.split(",") : value,
        };
      } else if (name === "shouldLoadLapData") {
        return {
          ...prev,
          [name]: checked,
          shouldLoadLapDataInRawForm: checked
            ? prev.shouldLoadLapDataInRawForm
            : false,
        };
      } else if (name === "shouldLoadLapDataInRawForm") {
        return {
          ...prev,
          shouldLoadLapData: checked ? true : prev.shouldLoadLapData,
          [name]: checked,
        };
      } else if (name === "newChannelName") {
        return {
          ...prev,
          [name]: !prev[name],
        };
      } else {
        return {
          ...prev,
          [name]: checked,
        };
      }
    });
  };

  return (
    <>
      {/* RaceDetails */}
      <div className="my-3 border rounded-1">
        <div className="py-3 pb-2 px-3 d-flex justify-content-between align-items-center">
          <Typography variant="h6">{selectedRace.event}</Typography>
        </div>
        <div className="py-3 px-3">
          <div className="d-flex align-items-center">
            <div className="d-flex my-3 align-items-center me-4">
              <IconButton>
                <LocationOnIcon />
              </IconButton>
              <span className="ms-2">{selectedRace.venue}</span>
            </div>
            <div className="d-flex my-3 align-items-center me-4">
              <IconButton>
                <EventIcon />
              </IconButton>
              <span className="ms-2">
                {new Date(selectedRace.date).toLocaleDateString()}
              </span>
            </div>
            <div className="d-flex my-3 align-items-center me-4">
              <IconButton>
                <ScheduleIcon />
              </IconButton>
              <span className="ms-2">{selectedRace.time}</span>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="d-flex my-3 align-items-center me-4">
              <IconButton>
                <PersonIcon />
              </IconButton>
              <span className="ms-2">{selectedRace.driver}</span>
            </div>
            <div className="d-flex my-3 align-items-center me-4">
              <IconButton>
                <DirectionsCarIcon />
              </IconButton>
              <span className="ms-2">{selectedRace.vehicle_desc}</span>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div className="d-flex my-3 align-items-center me-4">
              <span>Total Lap Count: {selectedRace.laps?.length}</span>
            </div>
          </div>
        </div>
      </div>
      {/* HEADER */}

      {/* Channel Filter */}
      <div className="my-3 p-3 border rounded-1">
        <FormControl fullWidth className="my-3">
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={channelData}
            value={channelData.filter((channel) =>
              filterFormGroup.channelNames.includes(channel.id)
            )}
            onChange={(event, newValue) => {
              handleFilterFormGroup({
                target: {
                  name: "channelNames",
                  value: newValue.map((item) => item.id),
                },
              });
            }}
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Channel Names" variant="outlined" />
            )}
          />
        </FormControl>

        {/*Lap Filters*/}
        <FormControl fullWidth className="my-3">
          <Autocomplete
            multiple
            disableCloseOnSelect
            options={lapData}
            value={lapData.filter((lap) =>
              filterFormGroup.lapNumbers.includes(lap.lapIndex)
            )}
            onChange={(event, newValue) => {
              handleFilterFormGroup({
                target: {
                  name: "lapNumbers",
                  value: newValue.map((item) => item.lapIndex),
                },
              });
            }}
            getOptionLabel={(option) =>
              `Lap ${option.lapIndex}: ${option.duration.toFixed(2)}s`
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ marginRight: 8 }}
                  checked={selected}
                />
                <div className="d-flex align-items-center justify-content-between w-100">
                  <div>
                    <span>Lap {option.lapIndex}</span>
                    <span className="mx-3">
                      LapTime: {option.duration.toFixed(2)}
                    </span>
                    <span
                      className="mx-1"
                      style={{
                        color:
                          compareDuration(
                            option.duration,
                            option.averageDuration
                          ) === 1
                            ? "red"
                            : compareDuration(
                                option.duration,
                                option.averageDuration
                              ) === -1
                            ? "green"
                            : "blue",
                      }}
                    >
                      {option.duration > option.averageDuration ? "+" : "-"}
                      {getDiff(option.duration, option.averageDuration).toFixed(
                        2
                      )}{" "}
                      Δ
                    </span>
                  </div>
                  <span className="mx-3">
                    Avg. LapTime: {option.averageDuration.toFixed(2)}
                  </span>
                </div>
              </li>
            )}
            renderInput={(params) => (
              <TextField {...params} label="Lap Numbers" variant="outlined" />
            )}
          />
        </FormControl>

        <div className="my-3 d-flex align-items-center">
          <span className="me-3">Load Lap Data:</span>
          <Switch
            name="shouldLoadLapData"
            checked={filterFormGroup.shouldLoadLapData}
            onChange={handleFilterFormGroup}
          />
        </div>

        <div className="my-3 d-flex align-items-center">
          <span className="me-3">
            Load Data in Raw Form (Motec/Convertor Form):
          </span>
          <Switch
            name="shouldLoadLapDataInRawForm"
            checked={filterFormGroup.shouldLoadLapDataInRawForm}
            onChange={handleFilterFormGroup}
          />
        </div>
        <div className="my-3 d-flex align-items-center">
          <span className="me-3">Primary Channel Names:</span>
          <Switch
            name="newChannelName"
            checked={filterFormGroup.newChannelName}
            onChange={handleFilterFormGroup}
          />
        </div>
      </div>
    </>
  );
}

export default RaceFilter;
